html, body, #root, .App {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#main {
  position: relative;
  width: 100%;
  height: calc(100% - 100px); /* height of #toolbar plus 10px padding */
}

#product-image-wrapper {
  position: relative;
  float: left;
  width: calc(100% - 90px);
  height: 100%;
}

#toolbar {
  height: 90px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 30px;
  background-color: #fff;
}
#colour-switcher{
  padding: 5px;
  text-align: left;
  flex: 0 0 auto;
}

.btn-colour-picker {
  border: black solid 1px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  padding: 0;
}
.btn-wrapper {
  border-top: none;
  padding-top: 3px;
  margin-right: 3px;
  width: 60px;
  float: left;
}
.btn-wrapper.active {
  border-top: rgb(0, 132, 255) solid 3px;
  padding-top: 0;
}

.btn-wrapper + .btn-wrapper {
  margin-left: 5px;
}

#product-name,
#product-info {
  font-family: Georgia, serif;
  /*flex: 1 1 auto;*/
  max-height: 80px;
  /*max-width: 70%;*/
  overflow: hidden;
}

#product-name {
  text-align: center;
  max-width: 70%;
}

#product-info {
  text-align: left;
}

#product-name h1 {
  margin: 0;
  line-height: 1.2em;
}

#product-name p,
#product-info p  {
  margin: 0;
  line-height: 1.2em;
}

#product-info p {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

#product-info .info-label {
  font-size: 0.8em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  color: #888;
  padding-right: 0.5em;
}

.model-picker {
  position: relative;
  float: left;
  width: 90px;
  height: 100%;
  padding: 0;
}
.model-picker button {
  float: left;
  height: 60px;
  margin-right: 5px;
}
.model-picker .product-list {
  float: right;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px 5px;
  width: 80px;
}

.product-item {
  list-style: none;
  margin: 5px 0;
}
.product-item img {
  height: 60px;
}